import {XHTTP} from '@/shared/xhttp';
import {PrestationService} from '@/xhttp';
import {PrestationType} from './PrestationType';
import {Contrat} from './Contrat';
import {DocumentPrestation} from "./DocumentPrestation";
import {DocumentPrestationCubage} from "./DocumentPrestationCubage";
import {Chantier} from './Chantier';
import {Parcelle} from './Parcelle';
import {Deposit} from './Deposit';
import {M3Price} from './M3Price';
import {SuiviReglement} from './SuiviReglement';
import {DateHelper} from "@/shared/utils";

export type BuyType = 'COUPE_RASE'|'COUPE_ECLAIRCI'; 
export const BuyType = {
	COUPE_RASE    :'COUPE_RASE'     as BuyType,
	COUPE_ECLAIRCI:'COUPE_ECLAIRCI' as BuyType,
};

export type CVO = 'NON'|'0.5'|'0.3';
export const CVO = {
	NON   : 'NON' as CVO,
	VAL_05: '0.5' as CVO,
	VAL_03: '0.3' as CVO,
};

export type PaymentType = 'AT_SIGN'|'AT_END'|'DEPOSIT';
export const PaymentType = {
	AT_SIGN:'AT_SIGN' as PaymentType,
	AT_END :'AT_END'  as PaymentType,
	DEPOSIT:'DEPOSIT' as PaymentType,
};

@XHTTP(PrestationService, '/prestations')
export class Prestation {

	private _id: number;
	private _prestationType: PrestationType;
	private _remarques: string;
	private _GPS: string;
	private _cubageEstimatif: string;
	private _localisation: string;
	private _commune: string;
	private _client: string;
	private _contrat: Contrat;
	private _price: number = 0.0;
	private _estimatedPriceLivraison: number = 0.0;
	private _tva: number = 0.2;
	private _buyType: BuyType;
	private _cvo: CVO = CVO.VAL_05;
	private _dtEnd: Date = new Date(); // Date limite de coupe
	private _finish: boolean = false;
	private _chantierBucheron: boolean = false;
	private _achatTerrain: boolean = false;
	private _payer: boolean = false;
	private _paiementPreparer: boolean = false;
	private _paymentType: PaymentType;
	private _chantier: Chantier;
	private _parcelles: Parcelle[];
	private _deposits: Deposit[] = [];
	private _m3Prices: M3Price[] = [];
	private _reglements: SuiviReglement[] = [];
	private _montantRegler: number = 0.0;
	private _documents: DocumentPrestation[] = [];
	private _documentsCubage: DocumentPrestationCubage[] = [];
	private _traverseCoursEau: boolean = false;
	private _demandeDTT: boolean = false;
	private _autreAlerte: boolean = false;
	private _texteAlerte: string;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get prestationType(): PrestationType {
		return this._prestationType;
	}

	public get remarques(): string {
		return this._remarques;
	}

	public get GPS(): string {
		return this._GPS;
	}

	public get cubageEstimatif(): string {
		return this._cubageEstimatif;
	}

	public get localisation(): string {
		return this._localisation;
	}

	public get commune(): string {
		return this._commune;
	}

	public get client(): string {
		return this._client;
	}

	public get contrat(): Contrat {
		return this._contrat;
	}

	public get price(): number {
		return this._price;
	}

	public get montantRegler(): number {
		return this._montantRegler;
	}

	public get tva(): number {
		return this._tva;
	}

	public get estimatedPriceLivraison(): number {
		return this._estimatedPriceLivraison;
	}

	public get totalPriceSale(): number {
		return (this.prestationType && this.prestationType.sale ? this.price : 0) + this.estimatedPriceLivraison;
	}

	public get totalPriceBuy(): number {
		return !this.prestationType.sale ? this.price : 0;
	}

	public get buyType(): BuyType {
		return this._buyType;
	}

	public get cvo(): CVO {
		return this._cvo ? <any>this._cvo.toString() : null;
	}

	public get cvo_value(): number {
		let value = 0;

		if (this._cvo == CVO.VAL_05)
		{
			value = 0.5 * this._price / 100;
		}
		if (this._cvo == CVO.VAL_03)
		{
			value = 0.2 * this._price / 100;
		}

		return value;
	}

	public get dtEnd(): Date {
		return this._dtEnd;
	}

	public get finish(): boolean {
		return this._finish;
	}

	public get chantierBucheron(): boolean {
		return this._chantierBucheron;
	}

	public get achatTerrain(): boolean {
		return this._achatTerrain;
	}

	public get payer(): boolean {
		return this._payer;
	}
	
	public get paiementPreparer(): boolean {
		return this._paiementPreparer;
	}

	public get traverseCoursEau(): boolean {
		return this._traverseCoursEau;
	}

	public get demandeDTT(): boolean {
		return this._demandeDTT;
	}

	public get autreAlerte(): boolean {
		return this._autreAlerte;
	}

	public get texteAlerte(): string {
		return this._texteAlerte;
	}

	public get avecAlerte(): boolean 
	{
		if ((this.traverseCoursEau == false) && 
		(this.demandeDTT == false) &&
		(this.autreAlerte == false))
			return false;	
		else
			return true;	
	}

	public get resumeAlerte(): string {
		if ((this.traverseCoursEau == false) && 
		(this.demandeDTT == false) &&
		(this.autreAlerte == false))
			return "Aucune";

		var $Resume = "";
		if (this.traverseCoursEau)
			$Resume = "Traversé cours d'eau, "

		if (this.demandeDTT)
			$Resume += "Demande DTT, "

		if (this.autreAlerte)
			$Resume += this._texteAlerte

		return $Resume;
	}

	public get paymentType(): PaymentType {
		return this._paymentType;
	}

	public get chantier(): Chantier {
		return this._chantier;
	}

	public get parcelles(): Parcelle[] {
		return this._parcelles;
	}

	public get deposits(): Deposit[] {
		return this._deposits;
	}

	public get m3Prices(): M3Price[] {
		return this._m3Prices;
	}

	public get reglements(): SuiviReglement[] {
		return this._reglements;
	}

	public get documents(): DocumentPrestation[] {
		return this._documents;
	}

	public get documentsCubage(): DocumentPrestationCubage[] {
		return this._documentsCubage;
	}

	/////////////
	// Setters //
	/////////////

	public set prestationType(value: PrestationType) {
		this._prestationType = value;
	}

	public set remarques(value: string) {
		this._remarques = value;
	}

	public set GPS(value: string) {
		this._GPS = value;
	}

	public set cubageEstimatif(value: string) {
		this._cubageEstimatif = value;
	}

	public set localisation(value: string) {
		this._localisation = value;
	}

	public set commune(value: string) {
		this._commune = value;
	}

	public set client(value: string) {
		this._client = value;
	}

	public set contrat(value: Contrat) {
		this._contrat = value;
	}

	public set price(value: number) {
		this._price = value;
	}

	public set montantRegler(value: number) {
		this._montantRegler = value;
	}

	public set tva(value: number) {
		this._tva = value;
	}

	public set buyType(value: BuyType) {
		this._buyType = value;
	}

	public set cvo(value: CVO) {
		this._cvo = value;
	}

	public set dtEnd(value: Date) {
		this._dtEnd = value;
	}

	public set finish(value: boolean) {
		this._finish = value;
	}

	public set chantierBucheron(value: boolean) {
		this._chantierBucheron = value;
	}

	public set achatTerrain(value: boolean) {
		this._achatTerrain = value;
	}

	public set payer(value: boolean) {
		this._payer = value;
	}
	
	public set paiementPreparer(value: boolean) {
		this._paiementPreparer = value;
	}

	public set traverseCoursEau(value: boolean) {
		this._traverseCoursEau = value;
	}

	public set demandeDTT(value: boolean) {
		this._demandeDTT = value;
	}

	public set autreAlerte(value: boolean) {
		this._autreAlerte = value;
	}

	public set texteAlerte(value: string) {
		this._texteAlerte = value;
	}

	public set paymentType(value: PaymentType) {
		this._paymentType = value;
	}

	public set chantier(value: Chantier) {
		this._chantier = value;
	}

	public set deposits(value: Deposit[]) {
		this._deposits = value;
	}

	public set parcelles(value: Parcelle[]) {
		this._parcelles = value;
	}

	public set m3Prices(value: M3Price[]) {
		this._m3Prices = value;
	}

	public set reglements(value: SuiviReglement[]) {
		this._reglements = value;
	}

	/////////
	// Add //
	/////////

	public addDocuments(document: DocumentPrestation): this {
		document.prestation = this;
		if (this._documents.indexOf(document) === -1) {
			this._documents.push(document);
		}
		return this;
	}

	public removeDocuments(document: DocumentPrestation): this {
		document.prestation = this;
		const index = this._documents.indexOf(document);
		if (index !== -1) {
			this._documents.splice(index, 1);
		}
		return this;
	}
	
	public addDocumentsCubage(document: DocumentPrestationCubage): this {
		document.prestation = this;
		if (this._documentsCubage.indexOf(document) === -1) {
			this._documentsCubage.push(document);
		}
		return this;
	}

	public removeDocumentsCubage(document: DocumentPrestationCubage): this {
		document.prestation = this;
		const index = this._documentsCubage.indexOf(document);
		if (index !== -1) {
			this._documentsCubage.splice(index, 1);
		}
		return this;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		const json = {
			id            : this._id,
			prestationType: this._prestationType ? this._prestationType.id : null,
			remarques  	  : this._remarques,
			GPS  	  : this._GPS,
			cubageEstimatif  	  : this._cubageEstimatif,
			localisation  	  : this._localisation,
			commune  	  : this._commune,
			client  	  : this._client,
			contrat       : this._contrat ? this._contrat.id : null,
			price         : this._price,
			montantRegler : this._montantRegler,
			tva           : this._tva,
			finish        : this._finish,
			chantierBucheron        : this._chantierBucheron,
			achatTerrain        : this._achatTerrain,
			payer        : this._payer,
			paiementPreparer        : this._paiementPreparer,
			traverseCoursEau   : this._traverseCoursEau,
			demandeDTT         : this._demandeDTT,
			autreAlerte        : this._autreAlerte,
			texteAlerte        : this._texteAlerte,
			buyType: null,
			cvo: null,
			dtEnd: null,
			chantier: this._chantier ? this._chantier.id : null,
			paymentType: null,
			deposits: [],
			parcelles: this._parcelles ? this._parcelles.map(p => p.toJSON()) : [],
			m3Prices: this._m3Prices ? this._m3Prices.map(m => m.toJSON()) : [],
			suiviReglements: this._reglements ? this._reglements.map(r => r.toJSON()) : [],
		};
		if (!this.prestationType.sale) {
			json['buyType' ] = this._buyType;
			json['cvo'     ] = this._cvo ? this._cvo.toString() : null;
			json['dtEnd'   ] = this._dtEnd ? DateHelper.toLocalizedString(this._dtEnd) : null;
			json['paymentType'] = this._paymentType;
			json['deposits'] = this._paymentType === PaymentType.DEPOSIT ? this._deposits.map(d => d.toJSON()) : [];
		}

		return json;
	}

	public fromJSON(data: any): this {
		this._id                      = data.id
		this._prestationType          = data.prestationType ? (new PrestationType()).fromJSON(data.prestationType) : null;
		this._remarques               = data.remarques;
		this._GPS               = data.GPS;
		this._cubageEstimatif               = data.cubageEstimatif;
		this._localisation               = data.localisation;
		this._commune               = data.commune;
		this._client               = data.client;
		this._contrat                 = data.contrat ? (new Contrat()).fromJSON(data.contrat) : null;
		this._price                   = data.price;
		this._montantRegler                   = data.montantRegler;
		this._estimatedPriceLivraison = data.estimatedPriceLivraison;
		this._tva                     = data.tva;
		this._buyType                 = data.buyType;
		this._cvo                     = data.cvo;
		this._dtEnd                   = data.dtEnd ? DateHelper.fromLocalizedString(data.dtEnd) : null;
		this._finish                  = data.finish;
		this._chantierBucheron                  = data.chantierBucheron;
		this._achatTerrain                  = data.achatTerrain;
		this._payer                   = data.payer;
		this._paiementPreparer        = data.paiementPreparer;
		this._traverseCoursEau        = data.traverseCoursEau;
		this._demandeDTT              = data.demandeDTT;
		this._autreAlerte             = data.autreAlerte;
		this._texteAlerte             = data.texteAlerte;
		this._paymentType             = data.paymentType;
		this._chantier                = data.chantier ? (new Chantier()).fromJSON(data.chantier) : null;
		this._parcelles               = data.parcelles ? data.parcelles.map(p => (new Parcelle()).fromJSON(p)) : [];
		this._deposits                = data.deposits ? data.deposits.map(d => (new Deposit()).fromJSON(d)) : [];
		this._m3Prices                = data.m3Prices ? data.m3Prices.map(m => (new M3Price()).fromJSON(m)) : [];
		this._reglements                = data.suiviReglements ? data.suiviReglements.map(r => (new SuiviReglement()).fromJSON(r)) : [];
		if (data.documentPrestations)  {
			for (const d of data.documentPrestations) {
				this.addDocuments((new DocumentPrestation()).fromJSON(d));
			}
		}
		if (data.documentPrestationCubages)  {
			for (const d of data.documentPrestationCubages) {
				this.addDocumentsCubage((new DocumentPrestationCubage()).fromJSON(d));
			}
		}
		return this;
	}

	////////////
	// Others //
	////////////

	public toString(): string {
		return this._prestationType.toString();
	}

}
