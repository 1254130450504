import {Prestation} from '@/models';

export default [
	{
		path: '/prestation',
		name: 'prestation',
		meta: {
			title: 'Liste des prestations',
			granted: [ 'API_GET_PRESTATION' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Prestation.vue')
	},
	{
		path: '/reglementsProprietaire',
		name: 'reglementsProprietaire',
		meta: {
			title: 'Réglements propriétaires en attente',
			granted: [ 'API_GET_PRESTATION' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/ReglementsProprietaires.vue')
	},
	{
		path: '/prestation-map',
		name: 'prestation-map',
		meta: {
			title: 'MAP des prestations',
			granted: [ 'API_GET_PRESTATION' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Prestation-map.vue')
	},
	{
		path: '/prestations-attentes-cubages',
		name: 'prestations-attentes-cubages',
		meta: {
			title: 'Cubages estimatifs en attente',
			granted: [ 'API_GET_PRESTATION' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Prestations-attentes-cubages.vue')
	},
	{
		path: '/prestation/show/:id',
		name: 'prestation-show',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Prestation/Show.vue'),
				class: Prestation
			}),
			editRoute: { name: 'prestation-edit' },
		},
		meta: {
			title: 'Détails d\'une prestation',
			granted: [ 'API_GET_PRESTATION' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
	{
		path: '/prestation/edit/:id',
		name: 'prestation-edit',
		props: {
			slotData: () => ({
				component: import(/* webpackChunkName: "dashboard" */ '../components/Crud/Prestation/Edit.vue'),
				class: Prestation
			}),
		},
		meta: {
			title: 'Edition d\'une prestation',
			granted: [ 'API_PUT_PRESTATION' ]
		},
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/EditShow.vue')
	},
];
