import {XHTTP} from '@/shared/xhttp';
import {CubageFactureService} from '@/xhttp';
import {DateHelper} from "@/shared/utils";
import {BoisType} from './BoisType';
import {BoisQuality} from './BoisQuality';
import {BoisSize} from './BoisSize';
import {Marquage} from './Marquage';
import {Chantier} from './Chantier';
import {Facture} from './Facture';
import {Purchase} from "./Purchase";
import {Tiers} from "./Tiers";
import {Chauffeur} from '@/models/Chauffeur';

@XHTTP(CubageFactureService, '/cubage-factures')
export class CubageFacture {

	private _id: number;
	private _facture: Facture;
	private _boisType: BoisType;
	private _boisQuality: BoisQuality;
	private _volume: number;
	private _prix: number;
	private _m3: boolean = false;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get facture(): Facture {
		return this._facture;
	}

	public get boisType(): BoisType {
		return this._boisType;
	}

	public get boisQuality(): BoisQuality {
		return this._boisQuality;
	}

	public get volume(): number {
		return this._volume;
	}

	public get prix(): number {
		return this._prix;
	}

	public get m3(): boolean {
		return this._m3;
	}

	public get stere(): boolean {
		return !this._m3;
	}

	/////////
	// Add //
	/////////

	/////////////
	// Setters //
	/////////////

	public set facture(value: Facture) {
		this._facture = value;
	}
	
	public set boisType(value: BoisType) {
		this._boisType = value;
	}

	public set boisQuality(value: BoisQuality) {
		this._boisQuality = value;
	}

	public set volume(value: number) {
		this._volume = value;
	}

	public set prix(value: number) {
		this._prix = value;
	}

	public set m3(value: boolean) {
		this._m3 = value;
	}

	public set stere(value: boolean) {
		this._m3 = !value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			facture         : this._facture ? this._facture.id : null,
			boisType    : this._boisType ? this._boisType.id : null,
			boisQuality : this._boisQuality ? this._boisQuality.id : null,
			volume : this._volume,
			prix: this._prix,
			m3: this._m3,
		};
	}

	public fromJSON(data: any): this {
		this._id           = data.id
		this._facture      = data.facture ? (new Facture).fromJSON(data.facture) : null;
		this._boisType     = data.boisType ? (new BoisType()).fromJSON(data.boisType) : null;
		this._boisQuality  = data.boisQuality ? (new BoisQuality()).fromJSON(data.boisQuality) : null;
		this._volume = data.volume;
		this._prix         = data.prix;
		this._m3 = data.m3;
		return this;
	}
}
